import { withWidth } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import React, { FC, ReactNode, useMemo } from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper } from "swiper/react"
import "swiper/swiper-bundle.min.css"

SwiperCore.use([Autoplay])

interface CarouselProps {
  children: ReactNode
  width: Breakpoint
  speed?: number
}

const Carousel: FC<CarouselProps> = ({ children, width, speed }) => {
  const slidesPerView = useMemo(() => {
    if (width === "lg" || width === "xl") return 3
    if (width === "md") return 2

    return 1
  }, [width])

  return (
    <Swiper
      spaceBetween={16}
      slidesPerView={slidesPerView}
      autoplay={{ delay: speed || 1500 }}
    >
      {children}
    </Swiper>
  )
}

export default withWidth()(Carousel)
