export const srcConcat = (id: string) =>
  `${process.env.CMS_URL || "https://cms.blackblock.studio/assets"}/${id}`

export const repeatArr = (arr: Array<unknown>, times = 5) => {
  const newArray = []
  for (let i = 0; i < times; i++) {
    newArray.push(...arr)
  }

  return newArray
}
