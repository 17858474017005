import { Typography } from "@material-ui/core"
import React, { FC } from "react"
import styled from "styled-components"

const Box = styled.div<{ photo: string }>`
  display: flex;
  justify-content: center;
  cursor: pointer;

  .border {
    height: 370px;
    width: 290px;
    background: transparent;
    transition: border 1s;
    position: relative;
    &:hover {
      border: 1px solid #fff;
    }
  }
  .card {
    height: 390px;
    width: 310px;
    background: #808080;
    transition: background 0.8s;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .card-item {
    background: url("${({ photo }) => photo}") center center no-repeat;
    background-size: 300px;
    &:hover {
      background: url("${({ photo }) => photo}") left center no-repeat;
      background-size: 600px;
      h3,
      p {
        opacity: 1;
      }
      .fa {
        opacity: 1;
      }
    }
  }

  h3 {
    color: #000;
    margin: 20px;
    opacity: 0;
    transition: opacity 1s;
    background-color: #fff;
    padding: 8px;
  }

  p {
    opacity: 0;
    margin: 20px;
  }
`

interface ArtistCardProps {
  name: string
  photo: string
}

const ArtistCard: FC<ArtistCardProps> = ({ name, photo }) => {
  return (
    <Box photo={photo}>
      <div className="card card-item">
        <div className="border">
          <Typography variant="h3">{name}</Typography>
        </div>
      </div>
    </Box>
  )
}

export default ArtistCard
