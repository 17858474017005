import {
  Box,
  BoxProps,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import React, { FC, ReactNode } from "react"
import { useMediaUp } from "src/hooks"

interface SectionLayoutProps extends BoxProps {
  children: ReactNode
  title?: string
  light?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(15, 0),
    },
    backgroundColor: (light) =>
      light ? theme.palette.primary.light : "transparent",
  },
  line: {
    height: 1,
    width: "100%",
    maxWidth: 150,
    backgroundColor: "#fff",
    marginLeft: theme.spacing(1),
  },
  subTitle: {
    letterSpacing: 1.5,
  },
}))

const SectionLayout: FC<SectionLayoutProps> = ({
  children,
  title,
  light = false,
  ...boxProps
}) => {
  const classes = useStyles(light)
  const isMedium = useMediaUp("md")
  return (
    <Box {...boxProps} className={classes.wrapper}>
      <Container fixed>
        <div data-aos="fade-down">
          {title && (
            <Box mb={isMedium ? 4 : 2}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" className={classes.subTitle}>
                  BLACK BLOCK
                </Typography>
                <Box className={classes.line} />
              </Box>
              <Typography variant="h2">
                <span>{title}</span>
              </Typography>
            </Box>
          )}
        </div>
        {children}
      </Container>
    </Box>
  )
}

export default SectionLayout
