import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { FC } from "react"

const useStyles = makeStyles({
  text: {
    fontSize: 14,
    textAlign: "center",
  },
  anchor: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
})

const Footer: FC = () => {
  const classes = useStyles()
  return (
    <Box component="footer" my={6}>
      <Typography className={classes.text} color="textSecondary">
        &#169; Designed and developed by
      </Typography>
      <Typography
        className={clsx(classes.text, classes.anchor)}
        href="https://github.com/zrtsky"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
      >
        Zaritsky Pavel
      </Typography>
    </Box>
  )
}

export default Footer
