import React from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import { compose, withProps } from "recompose"
import { COORDINATES, GOOGLE_MAP_LINK, MAP_STYLES } from "src/helpers/constants"
import { pinIcon } from "src/helpers/inline-svg"

const CustomMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCOxGnrojv4TOH7YdF5BEBhavvfbwaAFeA&language=ru",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: 550 }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((): JSX.Element => {
  const redirectToGoogleMap = () => window.open(GOOGLE_MAP_LINK, "_blank")

  return (
    // @ts-ignore
    <GoogleMap
      zoom={15}
      center={COORDINATES}
      defaultOptions={{
        styles: MAP_STYLES,
        fullscreenControl: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
      }}
    >
      <Marker
        position={COORDINATES}
        icon={pinIcon}
        onClick={redirectToGoogleMap}
      />
    </GoogleMap>
  )
})

export default CustomMap
