import { CSS } from "@react-spring/web"
import React, { FC, useEffect, useState } from "react"
import Lottie from "react-lottie-player"
import * as fingerRight from "src/animations/finger-right.json"
import * as fingerTap from "src/animations/finger-tap.json"

const SwipeOrSelect: FC<{ style?: CSS.Properties<string | number> }> = ({
  style,
}) => {
  const [isTap, setIsTap] = useState(false)

  const toggle = (time: number) => {
    setTimeout(() => {
      setIsTap((prevState) => !prevState)
    }, time)
  }

  useEffect(() => {
    isTap ? toggle(1000) : toggle(2900)
  }, [isTap])

  return (
    <Lottie
      play
      loop
      animationData={isTap ? fingerTap : fingerRight}
      style={{ ...style, height: 130 }}
    />
  )
}

export default SwipeOrSelect
