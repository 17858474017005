import {
  Box,
  Card,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC, Ref, useRef } from "react"
import { SectionLayout } from "src/templates/layouts"
import ReactAudioPlayer from "react-audio-player"
import { srcConcat } from "src/helpers/funcs"
import { useI18next } from "gatsby-plugin-react-i18next"

const ALL_SERVICES = graphql`
  query SERVICES {
    cms {
      items {
        black_block_services(sort: "index") {
          id
          translations {
            title
            subtitle
            description
            price
            languages_code
          }
          before_example {
            id
          }
          after_example {
            id
          }
        }
      }
    }
  }
`

interface Data {
  cms: {
    items: {
      black_block_services: BlackBlockService[]
    }
  }
}

type Translation = {
  languages_code: "en" | "ro" | "ru"
  title: string
  subtitle: string
  description: string
  price: string
}

interface BlackBlockService {
  sub_title: null | string
  price: string
  id: string
  description: string
  title: string
  translations: Translation[]
  before_example: Audio | null
  after_example: Audio | null
}

interface Audio {
  id: string
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: "100%",
  },
  price: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  subTitle: {
    opacity: 0.6,
    letterSpacing: 1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(5),
    },
  },
}))

const PriceCards: FC = () => {
  const classes = useStyles()

  const { t, language } = useI18next()

  const {
    cms: {
      items: { black_block_services },
    },
  } = useStaticQuery<Data>(ALL_SERVICES)
  const beforeAudioRef = useRef(null)
  const afterAudioRef = useRef(null)

  const stopPlay = (ref: any) => ref?.current?.audioEl?.current.pause()

  return (
    <SectionLayout title={t("service")} light>
      <Grid container spacing={2}>
        {black_block_services.map(
          (
            { id, translations, after_example, before_example },
            index,
            origin
          ) => {
            if (!translations) {
              return
            }
            const { title, subtitle, price, description } = translations.find(
              (translation) => translation.languages_code === language
            )

            return (
              <Grid
                item
                md={
                  origin.length === index + 1 && origin.length % 2 !== 0
                    ? 12
                    : 6
                }
                xs={12}
                key={id}
              >
                <div data-aos="zoom-in" style={{ height: "100%" }}>
                  <Card elevation={0} className={classes.card}>
                    <Typography
                      variant="h3"
                      align="center"
                      className={classes.price}
                    >
                      <span>{title}</span>
                    </Typography>
                    <Typography color="primary" variant="h4" align="center">
                      <span>{price}</span>
                    </Typography>
                    <Typography align="center" className={classes.subTitle}>
                      <span>{subtitle}</span>
                    </Typography>
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      <span>{description}</span>
                    </Typography>
                    {after_example && before_example && (
                      <>
                        <Box
                          my={3}
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography>До обработки</Typography>
                          <Box mb={2} />
                          <ReactAudioPlayer
                            ref={beforeAudioRef}
                            src={srcConcat(before_example.id)}
                            onPlay={() => stopPlay(afterAudioRef)}
                            controls
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography>После обработки</Typography>
                          <Box mb={2} />
                          <ReactAudioPlayer
                            ref={afterAudioRef}
                            src={srcConcat(after_example.id)}
                            onPlay={() => stopPlay(beforeAudioRef)}
                            controls
                          />
                        </Box>
                      </>
                    )}
                  </Card>
                </div>
              </Grid>
            )
          }
        )}
      </Grid>
    </SectionLayout>
  )
}

export default PriceCards
