import { Box, Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import { SectionLayout } from "src/templates/layouts"
import parse from "html-react-parser"
import { useI18next } from "gatsby-plugin-react-i18next"

export interface Data {
  cms: {
    items: {
      black_block_our_works: BlackBlockOurWork[]
      black_block_our_videos: BlackBlockOurVideos[]
    }
  }
}

export interface BlackBlockOurWork {
  half_size: boolean
  track_iframe: string
  id: string
}

export interface BlackBlockOurVideos {
  iframe: string
  id: string
}

const ALL_OUR_WORKS = graphql`
  query ALL_OUR_WORKS {
    cms {
      items {
        black_block_our_works(sort: "index") {
          half_size
          track_iframe
          id
        }
        black_block_our_videos(sort: "index") {
          id
          iframe
        }
      }
    }
  }
`

const OurWorks: FC = () => {
  const { t } = useI18next()

  const {
    cms: {
      items: { black_block_our_works, black_block_our_videos },
    },
  } = useStaticQuery<Data>(ALL_OUR_WORKS)
  return (
    <SectionLayout title={t("our work")} light>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">{t("tracks")}</Typography>
        </Grid>
        <Box mt={5} />

        {black_block_our_works.map(({ half_size, id, track_iframe }) => (
          <Grid item md={half_size ? 6 : 12} xs={12} key={id}>
            <div data-aos="fade-up">{parse(track_iframe)}</div>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box mt={5}>
            <Typography variant="h3">{t("video")}</Typography>
          </Box>
        </Grid>
        {black_block_our_videos.map(({ id, iframe }) => (
          <Grid item xs={12} key={id}>
            <div data-aos="fade-up">{parse(iframe)}</div>
          </Grid>
        ))}
      </Grid>
    </SectionLayout>
  )
}

export default OurWorks
