import {
  alpha,
  Box,
  Card,
  List,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import parse from "html-react-parser"
import React, { FC, useCallback, useRef, useState } from "react"
import ReactAudioPlayer from "react-audio-player"
import Lottie from "react-lottie-player"
import * as fingerRight from "src/animations/finger-right.json"
import { Carousel } from "src/components"
import { srcConcat } from "src/helpers/funcs"
import { useMediaUp } from "src/hooks"
import { SectionLayout } from "src/templates/layouts"
import { SwiperSlide } from "swiper/react"
import YouTube from "react-youtube"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"

const ALL_CERTIFICATES = graphql`
  query ALL_CERTIFICATES {
    cms {
      items {
        black_block_gift_certificates(sort: "index") {
          id
          discount_price
          youtube_link
          translations {
            title
            description
            price
            languages_code
          }
          photo {
            id
          }
          before_example {
            id
          }
          after_example {
            id
          }
        }
      }
    }
  }
`

interface Data {
  cms: {
    items: {
      black_block_gift_certificates: BlackBlockGiftCertificate[]
    }
  }
}

type Translation = {
  languages_code: "en" | "ro" | "ru"
  title: string
  description: string
  price: string
}

interface BlackBlockGiftCertificate {
  id: string
  youtube_link?: string
  photo: Photo
  discount_price: number | null
  translations: Translation[]
  before_example: Photo | null
  after_example: Photo | null
}

interface Photo {
  id: string
}

const useStyles = makeStyles((theme: Theme) => ({
  itemImage: {
    width: "100%",
    objectFit: "contain",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      maxHeight: 350,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    opacity: 0.6,
  },
  card: {
    padding: theme.spacing(3, 2),
    height: "100%",
  },
  discountOldPrice: {
    textDecoration: "line-through",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  playerPreview: {
    userSelect: "none",
    cursor: "pointer",
    width: "100%",
    aspectRatio: "16 / 9",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.5),
    },
  },
  playerPreviewLabel: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  player: {
    width: "100%",
    height: "100%",
  },
  playerWrapper: {
    width: "100%",
    margin: theme.spacing(3, 2, 0),
    height: "30%",
    outline: "none",
    maxWidth: 1280,
    maxHeight: 720,
    [theme.breakpoints.up("md")]: {
      height: "80%",
    },
  },
}))

const GiftCertificates: FC = () => {
  const classes = useStyles()
  const { t, language } = useI18next()

  const [selectedLink, setSelectedLink] = useState("")

  const isSmallUp = useMediaUp("md")
  const {
    cms: {
      items: { black_block_gift_certificates },
    },
  } = useStaticQuery<Data>(ALL_CERTIFICATES)

  const beforeAudioRef = useRef(null)
  const afterAudioRef = useRef(null)

  const stopPlay = (ref: any) => ref?.current?.audioEl?.current.pause()

  const handleClickPreviewPlayer = useCallback(
    (videoLink: string) => setSelectedLink(videoLink),
    []
  )
  const handleClose = useCallback(() => setSelectedLink(""), [])

  const showPlayerModal = !!selectedLink

  return (
    <SectionLayout title={t("gift cards")}>
      <List>
        <div data-aos="fade-up">
          <Carousel speed={3000}>
            {black_block_gift_certificates.map(
              ({
                photo,
                id,
                translations,
                after_example,
                before_example,
                discount_price,
                youtube_link,
              }) => {
                if (!translations) return

                const { title, price, description } = translations.find(
                  (translation) => translation.languages_code === language
                )

                return (
                  <SwiperSlide key={id}>
                    <Card elevation={0} className={classes.card}>
                      <img
                        className={classes.itemImage}
                        src={srcConcat(photo.id)}
                        alt={photo.id}
                      />
                      <Typography align="center" variant="h3">
                        {title}
                      </Typography>
                      <Box mt={1} mb={3}>
                        {discount_price ? (
                          <Typography
                            variant="h4"
                            align="center"
                            color="primary"
                          >
                            <Box
                              component="span"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                component="span"
                                className={classes.discountOldPrice}
                              >
                                {discount_price}
                              </Typography>
                              {price}
                            </Box>
                          </Typography>
                        ) : (
                          <Typography
                            variant="h4"
                            align="center"
                            color="primary"
                          >
                            <span>{price}</span>
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        component="div"
                        className={classes.description}
                      >
                        {parse(description)}
                      </Typography>

                      {after_example && before_example && (
                        <>
                          <Box
                            my={3}
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <Typography>{t("beforeProcessing")}</Typography>
                            <Box mb={2} />
                            <ReactAudioPlayer
                              ref={beforeAudioRef}
                              src={srcConcat(before_example.id)}
                              onPlay={() => stopPlay(afterAudioRef)}
                              controls
                            />
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <Typography>{t("afterProcessing")}</Typography>
                            <Box mb={2} />
                            <ReactAudioPlayer
                              ref={afterAudioRef}
                              src={srcConcat(after_example.id)}
                              onPlay={() => stopPlay(beforeAudioRef)}
                              controls
                            />
                          </Box>
                        </>
                      )}
                      {youtube_link && (
                        <>
                          <Typography className={classes.playerPreviewLabel}>
                            {t("example")}
                          </Typography>
                          <Box
                            className={classes.playerPreview}
                            onClick={() =>
                              handleClickPreviewPlayer(youtube_link)
                            }
                          >
                            <PlayCircleFilledIcon style={{ fontSize: 60 }} />
                          </Box>
                        </>
                      )}
                    </Card>
                  </SwiperSlide>
                )
              }
            )}
          </Carousel>
          <Modal
            open={showPlayerModal}
            onClose={handleClose}
            className={classes.modal}
          >
            <Box className={classes.playerWrapper}>
              <YouTube
                videoId={selectedLink}
                className={classes.player}
                opts={{
                  width: "100%",
                  height: "100%",
                  playerVars: {
                    autoplay: 1,
                  },
                }}
              />
            </Box>
          </Modal>
        </div>
        {!isSmallUp && black_block_gift_certificates.length > 1 && (
          <Lottie
            play
            loop
            animationData={fingerRight}
            style={{ maxWidth: 120, margin: "16px auto 0" }}
          />
        )}
      </List>
    </SectionLayout>
  )
}

export default GiftCertificates
