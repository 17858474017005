import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { FC, lazy, Suspense } from "react"
import { LanguageChanger } from "src/components"

const YoutubeBackground = lazy(() => import("react-youtube-background"))

const useStyles = makeStyles((theme: Theme) => ({
  videoContainer: {
    minHeight: "73vh",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(2, 2, 2, 0.123697) 4.36%, rgba(6, 6, 6, 0.334516) 11.8%, rgba(11, 11, 11, 0.616296) 35.78%, rgba(15, 15, 15, 0.805767) 60.87%, rgba(16, 16, 16, 0.870013) 67.71%, #121212 100%)",
  },
  logo: {
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
    userSelect: "none",
    maxWidth: "100%",
    margin: "0 auto",
    width: 500,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(25),
    },
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}))

const Header: FC = () => {
  const classes = useStyles()
  const isSSR = typeof window === "undefined"

  return (
    <>
      <Container>
        <LanguageChanger />
      </Container>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <YoutubeBackground
            videoId="sDBMJZFMaa8"
            aspectRatio="16:9"
            nocookie
            style={{
              pointerEvents: "none",
              minHeight: "73vh",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Suspense>
      )}
      <Box className={classes.videoContainer}>
        <Container>
          <Box maxWidth={900} mx="auto">
            <div
              data-aos="fade-up"
              data-aos-delay="600"
              className={classes.imgContainer}
            >
              <img
                width="100%"
                src="/logo-header.webp"
                className={classes.logo}
                alt="black-block"
              />
              <h1
                style={{
                  opacity: 0,
                  position: "absolute",
                  userSelect: "none",
                }}
              >
                BLACK BLOCK STUDIO
              </h1>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Header
