import {
  Box,
  Card,
  Grid,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import { srcConcat } from "src/helpers/funcs"
import { SectionLayout } from "src/templates/layouts"
import ReactAudioPlayer from "react-audio-player"

export interface Data {
  cms: {
    items: {
      black_block_before_after: BlackBlockBeforeAfter[]
    }
  }
}

export interface BlackBlockBeforeAfter {
  title: string
  id: string
  after: Media
  before: Media
}

export interface Media {
  id: string
  type: string
}

const ALL_BEFORE_AFTER = graphql`
  query ALL_BEFORE_AFTER {
    cms {
      items {
        black_block_before_after {
          title
          id
          after {
            id
            type
          }
          before {
            id
            type
          }
        }
      }
    }
  }
`

const StyledCard = withStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}))(Card)

const BeforeAfterWorks: FC = () => {
  const {
    cms: {
      items: { black_block_before_after },
    },
  } = useStaticQuery<Data>(ALL_BEFORE_AFTER)

  return (
    <SectionLayout title="Сравните результат">
      {black_block_before_after.map(({ id, title, after, before }) => (
        <div key={id} data-aos="fade-up" data-aos-delay="300">
          <StyledCard elevation={0}>
            <Typography variant="h3">{title}</Typography>
            <Grid container>
              <AudioColumn title="До" src={before} />
              <AudioColumn title="После" src={after} />
            </Grid>
          </StyledCard>
        </div>
      ))}
    </SectionLayout>
  )
}

const AudioColumn: FC<{ src: Media; title: string }> = ({ src, title }) => (
  <Grid item md={6} xs={12}>
    <Box my={3}>
      <Typography align="center">{title}</Typography>
    </Box>
    <Box display="flex" justifyContent="center">
      <ReactAudioPlayer src={srcConcat(src.id)} controls />
    </Box>
  </Grid>
)

export default BeforeAfterWorks
