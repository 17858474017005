import {
  Box,
  Container,
  Drawer,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { FC, useState } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { generate } from "shortid"
import { ArtistCard, Carousel, SwipeOrSelect } from "src/components"
import { srcConcat } from "src/helpers/funcs"
import { useMediaUp } from "src/hooks"
import { SectionLayout } from "src/templates/layouts"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"

export interface Data {
  cms: {
    items: {
      black_block_artists: BlackBlockArtist[]
    }
  }
}

interface BlackBlockArtist {
  name: string
  about: null | string
  id: string
  photo: Photo
  tracks: Track[] | null
}

interface Photo {
  id: string
}

interface Track {
  iframe: string
  title: string
}

const ALL_ARTISTS = graphql`
  query ALL_ARTISTS {
    cms {
      items {
        black_block_artists(sort: "index") {
          name
          about
          id
          photo {
            id
          }
          tracks {
            iframe
            title
          }
        }
      }
    }
  }
`

const SimpleButton = styled.button`
  border: none;
  outline: 0;
  background: transparent;
  width: 100%;
`

const useStyles = makeStyles((theme: Theme) => ({
  wrapperShow: {
    padding: theme.spacing(5, 0),
  },
  artistPhoto: {
    width: 150,
    height: 150,
    objectFit: "cover",
    marginRight: 0,
    borderRadius: "50%",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  showText: {
    fontSize: 18,
    maxWidth: 700,
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: "block",
  },
  artistName: {
    color: "#fff",
  },
}))

const StyledDrawer = withStyles({
  paper: {
    height: "75vh",
  },
})(Drawer)

const Artists: FC = () => {
  const classes = useStyles()

  const { t } = useI18next()

  const {
    cms: {
      items: { black_block_artists },
    },
  } = useStaticQuery<Data>(ALL_ARTISTS)
  const isSmallUp = useMediaUp("md")
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState<BlackBlockArtist>(
    black_block_artists[0]
  )
  const showLess = () => setShow(false)
  const showMore = (id: string) => {
    setShowInfo(black_block_artists.find((artist) => artist.id === id))
    setShow(true)
  }
  return (
    <>
      <SectionLayout title={t("artists")}>
        <div data-aos="fade-up" data-aos-delay="300">
          <Carousel>
            {black_block_artists.map(({ id, name, photo }) => (
              <SwiperSlide key={id}>
                <SimpleButton onClick={() => showMore(id)}>
                  <ArtistCard name={name} photo={srcConcat(photo.id)} />
                  <Typography className={classes.artistName} variant="h4">
                    {name}
                  </Typography>
                </SimpleButton>
              </SwiperSlide>
            ))}
          </Carousel>
          <SwipeOrSelect style={{ maxWidth: 120, margin: "0 auto" }} />
        </div>
        <StyledDrawer anchor="bottom" open={show} onClose={showLess}>
          <Container fixed>
            <Box className={classes.wrapperShow}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection={isSmallUp ? "row" : "column"}
                mb={3}
              >
                <img
                  className={classes.artistPhoto}
                  src={srcConcat(showInfo.photo.id)}
                  alt={showInfo.photo.id}
                />
                <Typography variant="h1">
                  <span>{showInfo.name}</span>
                </Typography>
              </Box>
              <List>
                {showInfo.about && (
                  <ListItem>
                    <Box>
                      <Typography className={classes.sectionTitle} variant="h3">
                        О артисте
                      </Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.showText}
                      >
                        {showInfo.about}
                      </Typography>
                    </Box>
                  </ListItem>
                )}
                {showInfo?.tracks && (
                  <ListItem className={classes.listItem}>
                    <Box>
                      <Typography className={classes.sectionTitle} variant="h3">
                        Треки
                      </Typography>
                      <Grid container>
                        {showInfo?.tracks.map(({ iframe }) => (
                          <Grid item xs={12} key={generate()}>
                            {parse(iframe)}
                            <Box mb={2} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </ListItem>
                )}
              </List>
            </Box>
          </Container>
        </StyledDrawer>
      </SectionLayout>
    </>
  )
}

export default Artists
