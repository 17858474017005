import { Box } from "@material-ui/core"
import { animated } from "@react-spring/web"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC, Fragment } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import Lottie from "react-lottie-player"
import { generate } from "shortid"
import { SRLWrapper } from "simple-react-lightbox"
import * as fingerTap from "src/animations/finger-tap.json"
import { Carousel, SwipeOrSelect } from "src/components"
import { srcConcat } from "src/helpers/funcs"
import { useMediaUp, useParallax } from "src/hooks"
import { SectionLayout } from "src/templates/layouts"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"

const GALLERY_QUERY = graphql`
  {
    cms {
      items {
        black_block_gallery {
          photos {
            directus_files_id {
              id
            }
          }
        }
      }
    }
  }
`

interface Data {
  cms: {
    items: {
      black_block_gallery: {
        photos: DirectusFilesId[]
      }
    }
  }
}

interface DirectusFilesId {
  directus_files_id: FilesID | null
}

interface FilesID {
  id: string
}

const lightboxOptions = {
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
    showFullscreenButton: false,
  },
  caption: { showCaption: false },
}

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;

  @media (min-width: 600px) {
    width: 400px;
  }
`

const Gallery: FC = () => {
  const { t } = useI18next()

  const {
    cms: {
      items: {
        black_block_gallery: { photos },
      },
    },
  } = useStaticQuery<Data>(GALLERY_QUERY)
  const isMediumUp = useMediaUp("sm")

  return (
    <Box overflow="hidden">
      <SectionLayout title={t("gallery")} light>
        <div data-aos="fade-up">
          <SRLWrapper options={lightboxOptions}>
            {isMediumUp ? (
              <Box display="flex" ml={-500}>
                {photos.map(({ directus_files_id }, index) => (
                  <Fragment key={index}>
                    {directus_files_id && (
                      <GalleryItem image={srcConcat(directus_files_id.id)} />
                    )}
                  </Fragment>
                ))}
              </Box>
            ) : (
              <Carousel>
                {photos.map(({ directus_files_id }, index) => (
                  <Fragment key={index}>
                    {directus_files_id && (
                      <SwiperSlide key={index}>
                        <Image
                          src={srcConcat(directus_files_id.id)}
                          alt={directus_files_id.id}
                        />
                      </SwiperSlide>
                    )}
                  </Fragment>
                ))}
              </Carousel>
            )}
          </SRLWrapper>
          {isMediumUp ? (
            <Lottie
              play
              loop
              animationData={fingerTap}
              style={{ maxWidth: 130, margin: "0 auto" }}
            />
          ) : (
            <SwipeOrSelect style={{ maxWidth: 130, margin: "0 auto" }} />
          )}
        </div>
      </SectionLayout>
    </Box>
  )
}

interface GalleryItemProps {
  image: string
}

const GalleryItem: FC<GalleryItemProps> = ({ image }) => {
  const { ParallaxProvider, transform } = useParallax("X")
  const style = { transform: transform(0.5) }
  return (
    <Box m={2}>
      <ParallaxProvider>
        <animated.div style={style}>
          <Image src={image} alt={image} />
        </animated.div>
      </ParallaxProvider>
    </Box>
  )
}

export default Gallery
