import { Typography } from "@material-ui/core"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import styled from "styled-components"

const Box = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  gap: 5px;
  .lang {
    cursor: pointer;
  }
`

type Lang = "en" | "ru" | "ro"

const LANGS: Array<Lang> = ["en", "ru", "ro"]

const LanguageChanger: FC = () => {
  const { language, changeLanguage } = useI18next()

  return (
    <Box>
      {LANGS.map((l, index) => (
        <>
          <Typography
            className="lang"
            variant="h5"
            color={language !== l ? "textSecondary" : "initial"}
            onClick={() => changeLanguage(l)}
            key={l}
          >
            {l.toUpperCase()}
          </Typography>
          {index !== LANGS.length - 1 && <Typography>|</Typography>}
        </>
      ))}
    </Box>
  )
}

export default LanguageChanger
