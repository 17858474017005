import {
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import React, { FC } from "react"
import { CustomMap } from "src/components"
import {
  CONTACT_EMAIL,
  GOOGLE_MAP_LINK,
  PHONE_NUMBER,
} from "src/helpers/constants"
import email from "src/svgs/email.svg"
import location from "src/svgs/location.svg"
import phone from "src/svgs/phone.svg"
import fb from "src/svgs/fb.svg"
import instagram from "src/svgs/instagram.svg"
import youtube from "src/svgs/youtube.svg"
import social from "src/animations/social.json"
import Lottie from "react-lottie-player"
import { useI18next } from "gatsby-plugin-react-i18next"

import { SectionLayout } from "src/templates/layouts"

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(5),
    "& img": {
      marginRight: theme.spacing(2),
    },
  },
  subTitle: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const contactData: IListItem[] = [
  {
    icon: phone,
    link: "tel:" + PHONE_NUMBER,
    label: "phone",
    linkText: PHONE_NUMBER,
  },
  {
    icon: email,
    link: "mailto:" + CONTACT_EMAIL,
    label: "mail",
    linkText: CONTACT_EMAIL,
  },
  {
    icon: instagram,
    label: "Instagram",
    linkText: "blackblockofficial",
    link: "https://www.instagram.com/blackblockofficial/",
    newTab: true,
  },
  {
    icon: fb,
    label: "Facebook",
    linkText: "BlackBlockProduction",
    link: "https://www.facebook.com/BlackBlockProduction/",
    newTab: true,
  },
  {
    icon: youtube,
    label: "Youtube",
    linkText: "BlackBlockRec",
    link: "https://www.youtube.com/user/BlackBlockRec",
    newTab: true,
  },
  {
    icon: location,
    link: GOOGLE_MAP_LINK,
    label: "address",
    linkText: "Chisinau, Vadul lui Vodă 80/1",
    newTab: true,
  },
]

const Contacts: FC = () => {
  const { t } = useI18next()

  const classes = useStyles()
  return (
    <>
      <SectionLayout title={t("contacts")}>
        <Grid container>
          <Grid item md={5} xs={12}>
            <Box mt={4} />
            <List>
              {contactData.map(({ label, ...props }, index) => (
                <ItemList
                  key={index + props.link}
                  classes={classes}
                  label={t(label)}
                  {...props}
                />
              ))}
            </List>
          </Grid>
          <Hidden smDown>
            <Grid item md={7} xs={12}>
              <Box mt={4} />
              <div data-aos="zoom-in">
                <Lottie
                  play
                  loop
                  animationData={social}
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </SectionLayout>
      <CustomMap />
    </>
  )
}

interface IListItem {
  link: string
  newTab?: boolean
  linkText: string
  label: string
  icon: string
}

interface ItemListProps extends IListItem {
  classes: ClassNameMap<string>
}

const ItemList: FC<ItemListProps> = ({
  classes,
  icon,
  label,
  link,
  linkText,
  newTab,
}) => {
  const isNewTab = newTab
    ? { target: "_blank", rel: "noreferrer nofollow" }
    : {}
  return (
    <div data-aos="fade-up">
      <ListItem className={classes.listItem}>
        <img src={icon} alt="contact" />
        <Box>
          <Typography variant="h3">{label}</Typography>
          <Typography
            {...isNewTab}
            href={link}
            component="a"
            color="textSecondary"
            className={classes.subTitle}
          >
            {linkText}
          </Typography>
        </Box>
      </ListItem>
    </div>
  )
}

export default Contacts
