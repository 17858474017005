import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import Lottie from "react-lottie-player"
import * as music from "src/animations/music.json"
import { SectionLayout } from "src/templates/layouts"

const InfoSection: FC = () => {
  const isMediumUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))

  const { t, language } = useI18next()

  return (
    <SectionLayout style={{ paddingTop: 0 }}>
      <div data-aos="fade-down">
        <Lottie
          play
          loop
          animationData={music}
          style={{ marginBottom: 60, maxWidth: 300, margin: "0 auto" }}
        />
      </div>
      <div data-aos="fade-up">
        <Typography align={isMediumUp ? "center" : "left"} variant="h2">
          {t("professional record label")}
        </Typography>
        <Box mb={3} />
        <Typography
          style={{ whiteSpace: "pre-line" }}
          align={isMediumUp ? "center" : "left"}
        >
          {t("main info")}
        </Typography>
        <Box mb={2} />
        <Typography align={isMediumUp ? "center" : "left"}>
          {t("secondary info")}
        </Typography>
      </div>
    </SectionLayout>
  )
}
export default InfoSection
