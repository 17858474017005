import AOS from "aos"
import "aos/dist/aos.css"
import React, { useEffect } from "react"
import { Footer } from "src/templates/layouts"
import {
  Artists,
  Contacts,
  Gallery,
  GiftCertificates,
  Header,
  InfoSection,
  OurWorks,
  PriceCards,
} from "src/templates/main"

import { graphql } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 600,
    })
  }, [])

  return (
    <main style={{ overflowX: "hidden" }}>
      <Header />
      <InfoSection />
      <PriceCards />
      <GiftCertificates />
      <OurWorks />
      <Artists />
      <Gallery />
      <Contacts />
      <Footer />
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
